<template>
  <div id="game-sharing" class="container-fluid">
    <div class="page-header mb-1">
      <strong>ตั้งค่าเกม - ถือหุ้น/คอมมิชชั่น</strong>
    </div>

    <ol class="breadcrumb px-2 py-1 m-0 mb-2 border-warning">
      <li class="breadcrumb-item" v-for="breadcrumb in breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
        <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
        <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
      </li>
    </ol>

    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="cat in catItems" :key="cat._id" class="nav-item">
            <a class="nav-link" :class="[{'active':catId===cat._id}]" href="#" target="_self" @click="catId=cat._id">{{ cat.name }}</a>
          </li>
        </ul>
      </div>

      <div class="p-1">
        <button
          class="btn btn-sm m-1"
          v-for="item in creatorItems"
          :key="item._id"
          :class="[{'btn-primary': item._id===creId}, {'btn-outline-primary': item._id!=creId}]"
          @click="creId=item._id"
        >{{ item.name }}</button>
      </div>

      <div class="px-2">
        <table class="table table-bordered table-striped table-sm table-pp table-hover mb-2">
          <thead>
            <tr v-if="creId" class="bg-light">
              <th colspan="11" class="text-center font-weight-bold text-primary p-3">ค่ายเกม {{creatorName}}</th>
            </tr>
            <tr>
              <th rowspan="3" width="60">ลำดับ</th>
              <th rowspan="2" width="60">เลือก</th>
              <th rowspan="2">
                ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th width="180" rowspan="2">ระดับ</th>
              <th rowspan="2" width="180">สถานะ</th>
              <th colspan="3">แบ่งหุ้น</th>
              <th colspan="2" class="alert-info">คอมมิชชั่น</th>
              <th rowspan="2" width="60">แก้ไข</th>
            </tr>
            <tr>
              <td width="120">% ได้รับ</td>
              <td width="120">% แบ่งหุ้น</td>
              <td width="120">% เก็บของ</td>
              <td width="120" class="alert-info">% ได้รับ</td>
              <td width="120" class="alert-info">% จ่าย</td>
            </tr>
            <tr v-if="items.length">
              <td class="text-center">
                <b-form-checkbox v-model="checkAll" class="m-0" :value="true" :unchecked-value="false" />
              </td>
              <td colspan="2" class="text-center small">ตั้งค่ากับสมาชิกที่เลือก</td>
              <td class="text-left pl-3">
                <b-form-radio-group
                  id="bulk-status"
                  v-model="bulkData.isAvailable"
                  :options="statusOptions"
                  name="bulk-status"
                  stacked
                ></b-form-radio-group>
              </td>
              <td class="text-center">{{ shareReceive | amountFormat(2, '0.00') }}</td>
              <td class="text-left">
                <b-form-select
                  v-model="bulkData.shareHolder.receive"
                  :disabled="!bulkAccountIds.length"
                  :options="shareOptions"
                  size="sm"
                ></b-form-select>
              </td>
              <td>
                <span v-if="bulkAccountIds.length && bulkData.shareHolder.receive!==null">{{ (shareReceive - bulkData.shareHolder.receive) | amountFormat(2, '0.00') }}</span>
                <span v-else>-</span>
              </td>
              <td class="text-center alert-info">{{ comReceive | amountFormat(2, '0.00') }}</td>
              <td class="text-center alert-info">
                <b-form-select
                  v-model="bulkData.shareHolder.commission"
                  :disabled="!bulkAccountIds.length"
                  :options="comOptions"
                  size="sm"
                ></b-form-select>
              </td>
              <td class="text-center">
                <button @click="saveBulk" :disabled="!bulkAccountIds.length" class="btn btn-sm btn-success">บันทึก</button>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item.accountId">
              <td>{{ index+1 }}</td>
              <td>
                <b-form-checkbox
                  v-model="bulkAccountIds"
                  :value="item.accountId"
                />
              </td>
              <td>
                <span v-if="item.role==='Agent' && item.gameSettings[creId].isAvailable" class="link" @click="toggleChildAccount(item.accountId)">{{ item.user.username }}</span>
                <span v-else>{{ item.user.username }}</span>
                <small v-if="showName" class="text-secondary ml-1">({{ item.user.firstName }})</small>
              </td>
              <td><AccountRole :role="item.role" /></td>
              <td>
                <b-form-checkbox v-model="item.gameSettings[creId].isAvailable" name="check-button" switch @change="toggleChange(item)" :class="[item.gameSettings[creId].isAvailable ? 'text-success' : 'text-danger']">
                  {{ item.gameSettings[creId].isAvailable ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
              <td class="text-center">
                <span v-if="item.gameSettings[creId].isAvailable">{{ shareReceive | amountFormat(2, '0.00') }}</span>
                <i v-else class="fas fa-times text-secondary"></i>
              </td>
              <td>
                <span v-if="item.gameSettings[creId].isAvailable">{{ item.gameSettings[creId].shareHolder.receive | amountFormat(2, '0.00') }}</span>
                <i v-else class="fas fa-times text-secondary"></i>
              </td>
              <td>
                <span v-if="item.gameSettings[creId].isAvailable">{{ (shareReceive - item.gameSettings[creId].shareHolder.receive) | amountFormat(2, '0.00') }}</span>
                <i v-else class="fas fa-times text-secondary"></i>
              </td>
              <td class="text-center alert-info text-info">
                <span v-if="item.gameSettings[creId].isAvailable">{{ comReceive | amountFormat(2, '0.00') }}</span>
                <i v-else class="fas fa-times text-secondary"></i>
              </td>
              <td class="text-center alert-info">
                <span v-if="item.gameSettings[creId].isAvailable">{{ item.gameSettings[creId].shareHolder.commission | amountFormat(2, '0.00') }}</span>
                <i v-else class="fas fa-times text-secondary"></i>
              </td>
              <td>
                <button :disabled="!item.catSettings[catId]" class="btn btn-sm" :class="[item.catSettings[catId] ? 'btn-outline-primary' : 'btn-outline-secondary']" @click="edit(item)"><i class="far fa-edit"></i></button>
              </td>
            </tr>
            <tr v-if="!items.length">
              <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <SetGameSharingModal
      :ref-account-id="refAccountId"
      :ref-settings="refSettings"
      :account="setAccount"
      :cat-id="catId"
      :categories="catItems"
      :creators="creators"
      :is-show="isShowModal"
      @reload="loadData"
      @close="modalClosed"
    />
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'
import SetGameSharingModal from './components/SetGameSharingModal'

export default {
  name: 'GameSharing',
  components: {
    SetGameSharingModal
  },
  data() {
    return {
      accountId: this.$store.state.account._id,
      data: null,
      catId: null,
      creId: null,
      showName: false,
      checkAll: false,
      bulkAccountIds: [],
      bulkData: {
        isAvailable: null,
        shareHolder: {
          receive: null,
          referral: 0,
          over: false,
          commission: null
        }
      },
      setAccount: null,
      isShowModal: false
    }
  },
  computed: {
    currentAccountId() {
      return this.$store.state.account._id
    },
    refAccountId() {
      return this.data?.refAccountId ?? null
    },
    parentAccountId() {
      if(this.breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.breadcrumbs[this.breadcrumbs.length - 1].accountId
      }
    },
    breadcrumbs() {
      return this.data?.parents ?? []
    },
    catItems() {
      return (this.data?.categories ?? [])
      .map((cat)=>{
        return {
          _id: cat._id,
          name: cat.name
        }
      })
    },
    creators() {
      return this.data?.creators ?? []
    },
    creatorItems() {
      const creators = this.creators.filter((cre)=>{
        return cre.catId === this.catId
      })

      const isInCat = creators.find((cre)=>{
        return cre._id === this.creId
      })

      if(isInCat === undefined && creators.length) {
        this.creId = creators[0]._id
      }else
      if(isInCat === undefined) {
        this.creId = null
      }

      return creators
    },
    items() {
      return (this.data?.accounts ?? []).filter((acc)=>{
        return this.creId && acc.isAvailable && acc.catSettings[this.catId]
      })
    },
    creator() {
      return (this.data?.creators ?? []).find((cre)=>{
        return cre._id === this.creId
      })
    },
    creatorName() {
      return this.creator?.name
    },
    statusOptions() {
      return [
        { text: 'ไม่เปลี่ยนค่า', value: null },
        { text: 'เปิด', value: true },
        { text: 'ปิด', value: false }
      ]
    },
    shareOptions() {
      let sharePercents = [{
        text: 'ไม่เปลี่ยนค่า',
        value: null
      }]
      for(let i=this.shareReceive; i>=0; i-=0.5) {
        sharePercents.push({
          text: i.toFixed(1),
          value: i
        })
      }
      return sharePercents
    },
    comOptions() {
      let comPercents = [{
        text: 'ไม่เปลี่ยนค่า',
        value: null
      }]

      for(let i=(this.comReceive || 20); i>=0; i-=0.5) {
        comPercents.push({
          text: i.toFixed(1),
          value: i
        })
      }

      return comPercents
    },
    changeTab() {
      return { catId: this.catId, creId: this.creId }
    },
    refSettings() {
      return this.data?.refGameSettings ?? {}
    },
    refGameSettings() {
      return this.data?.refGameSettings?.[this.creId] ?? null
    },
    shareReceive() {
      return this.refGameSettings?.shareHolder?.receive ?? 0
    },
    comReceive() {
      return this.refGameSettings?.shareHolder?.commission ?? 0
    }
  },
  watch: {
    checkAll(n, o) {
      this.bulkAccountIds = []
      if(n){
        for(const item of this.items) {
          this.bulkAccountIds.push(item.accountId)
        }
      }
    },
    changeTab() {
      this.reset()
    }
  },
  methods: {
    loadData() {
      GameService.getChildAccounts(this.accountId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data

          if(this.catId===null && this.data.categories.length) {
            this.catId = this.data.categories[0]._id
          }
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message,
          icon: 'error',
          confirmButtonText: 'OK'
        }, ()=>{
          if(e.status === 404) {
            this.$router.push({ name: 'Dashboard'})
          }
        })
      })
      .finally(()=>{
        this.reset()
      })
    },
    reset() {
      this.checkAll = false
      this.bulkAccountIds = []
      this.bulkData = {
        isAvailable: null,
        shareHolder: {
          receive: null,
          referral: 0,
          over: false,
          commission: null
        }
      }
    },
    toggleChange(account) {
      const updateData = {
        accountIds: [account.accountId],
        refAccountId: this.refAccountId,
        update: [this.creId].reduce((obj, creId)=>{
          obj[creId] = {
            isAvailable: account.gameSettings[this.creId].isAvailable
          }
          return obj
        }, {}),
        updateChild: false
      }
      this.update(updateData)
    },
    saveBulk() {
      let updateData = {}
      if(this.bulkData.isAvailable !== null) {
        updateData['isAvailable'] = this.bulkData.isAvailable
      }

      if(this.bulkData.shareHolder.receive !== null || this.bulkData.shareHolder.commission !== null) {
        updateData['shareHolder'] = {}

        if(this.bulkData.shareHolder.receive !== null) {
          updateData['shareHolder']['receive'] = this.bulkData.shareHolder.receive
          updateData['shareHolder']['referral'] = (this.shareReceive - this.bulkData.shareHolder.receive)
        }

        if(this.bulkData.shareHolder.commission !== null) {
          updateData['shareHolder']['commission'] = this.bulkData.shareHolder.commission
        }
      }

      if(!Object.keys(updateData).length)
        return

      const data = {
        accountIds: this.bulkAccountIds,
        refAccountId: this.refAccountId,
        update: [this.creId].reduce((obj, creId)=>{
          obj[creId] = updateData
          return obj
        }, {}),
        updateChild: false
      }

      this.update(data)
    },
    update(data) {
      GameService.updateGameSetting(data)
      .then((response)=>{
        if(response.success) {
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.reset()
        this.loadData()
      })
    },
    edit(account) {
      this.setAccount = account
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadData()
      }
    },
    toggleChildAccount(accountId) {
      this.accountId = accountId
      this.loadData()
    },
  },
  mounted() {
    this.loadData()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th, td {
        text-align: center;
        font-weight: normal;
        vertical-align: middle;
      }

      th:first-child {
        // border-left: 0;
      }

      th:last-child {
        // border-right: 0;
      }
    }
  }

  tbody {
    background-color: #FFFFFF;
    tr {
      td {
        vertical-align: middle;
        text-align: center;

        .link {
          color: #007BFF;
        }
        .link:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      td:first-child {
        // border-left: 0;
      }

      td:last-child {
        // border-right: 0;
      }
    }
  }
}
</style>
