<template>
  <b-modal
    id="account-game-modal"
    ref="account-game-modal"
    hide-footer
    :title="title"
    @show="setData"
    @hidden="resetModal"
    size="xl"
    body-class="p-0"
  >
    <div class="card rounded-0 mb-0">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="cat in categories" :key="cat._id" class="nav-item">
            <a class="nav-link" :class="[{'active':setCatId===cat._id}]" href="#" target="_self" @click="setCatId=cat._id">{{ cat.name }}</a>
          </li>
        </ul>
      </div>

      <table class="table table-pp table-bordered table-hover table-striped table-sm mb-0">
        <thead>
          <tr>
            <th rowspan="3" width="60">ลำดับ</th>
            <th rowspan="2" width="60">เลือก</th>
            <th rowspan="2">ค่ายเกม</th>
            <th rowspan="2" width="180">สถานะ</th>
            <th colspan="3">แบ่งหุ้น</th>
            <th colspan="2" class="alert-info">คอมฯ</th>
          </tr>
          <tr>
            <td width="120">% ได้รับ</td>
            <td width="120">% แบ่งหุ้น</td>
            <td width="120">% เก็บของ</td>
            <td width="120" class="alert-info">% ได้รับ</td>
            <td width="120" class="alert-info">% จ่าย</td>
          </tr>
          <tr v-if="creatorItems.length">
            <td class="text-center">
              <b-form-checkbox v-model="checkAll" class="m-0" :value="true" :unchecked-value="false" />
            </td>
            <td class="text-center small">ตั้งค่ากับที่เลือก</td>
            <td class="text-center pl-3">
              <b-form-radio-group
                v-model="bulkData.isAvailable"
                :options="statusOptions"
                :disabled="!bulkCreatorIds.length"
              ></b-form-radio-group>
            </td>
            <td>
              <small v-if="minReceive !== maxReceive">{{ minReceive | amountFormat(2, '0.00') }} - {{ maxReceive | amountFormat(2, '0.00') }}</small>
              <small v-else>{{ minReceive | amountFormat(2, '0.00') }}</small>
            </td>
            <td class="text-center">
              <b-form-select
                style="width: 120px;"
                v-model="bulkData.shareHolder.receive"
                :disabled="!bulkCreatorIds.length"
                :options="shareOptions"
                size="sm"
              ></b-form-select>
            </td>
            <td>-</td>
            <td class="alert-info">
              <small v-if="minCommission !== maxCommission">{{ minCommission | amountFormat(2, '0.00') }} - {{ maxCommission | amountFormat(2, '0.00') }}</small>
              <small v-else>{{ minCommission | amountFormat(2, '0.00') }}</small>
            </td>
            <td class="text-center alert-info">
              <div class="d-flex justify-content-center">
                <b-form-select
                  v-model="bulkData.shareHolder.commission"
                  :disabled="!bulkCreatorIds.length"
                  :options="comOptions"
                  size="sm"
                ></b-form-select>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in creatorItems" :key="item._id">
            <td>{{ index+1 }}</td>
            <td>
              <b-form-checkbox
                v-model="bulkCreatorIds"
                :value="item._id"
              />
            </td>
            <td>{{ item.name }}</td>
            <td>
              <b-form-checkbox
                v-model="gameSettings[item._id].isAvailable"
                name="check-button"
                switch
                :class="[gameSettings[item._id].isAvailable ? 'text-success' : 'text-danger']">
                  {{ gameSettings[item._id].isAvailable ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
            </td>
            <td>{{ refSettings[item._id].shareHolder.receive | amountFormat(2, '0.00') }}</td>
            <td>
              <b-form-select
                style="width: 120px;"
                v-model="gameSettings[item._id].shareHolder.receive"
                :disabled="!gameSettings[item._id].isAvailable"
                :options="shareOptions"
                size="sm"
              ></b-form-select>
            </td>
            <td>{{ (refSettings[item._id].shareHolder.receive - gameSettings[item._id].shareHolder.receive) | amountFormat(2, '0.00') }}</td>
            <td class="alert-info">{{ refSettings[item._id].shareHolder.commission | amountFormat(2, '0.00') }}</td>
            <td class="text-center alert-info">
              <div class="d-flex justify-content-center">
                <b-form-select
                  v-model="gameSettings[item._id].shareHolder.commission"
                  :disabled="!gameSettings[item._id].isAvailable"
                  :options="comOptions"
                  size="sm"
                ></b-form-select>
              </div>
            </td>
          </tr>
          <tr v-if="!creatorItems.length">
            <td colspan="9" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
      <div v-if="creatorItems.length" class="p-2 d-flex justify-content-between align-items-center">
        <b-form-checkbox v-model="updateAllAccount" class="m-0" :value="true" :unchecked-value="false">บันทึกกับสมาชิกทั้งหมด</b-form-checkbox>
        <div>
          <button class="btn btn-danger mr-2" @click="resetChanged">Reset</button>
          <button class="btn btn-success" @click="saveChanged">บันทึก</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import GameService from '@/services/GameService'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'
import _ from 'lodash'

export default {
  name: 'SetGameSharingModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    refSettings: {
      type: Object,
      default: () => {
        return {}
      }
    },
    refAccountId: {
      type: String,
      default: null
    },
    account: {
      type: Object,
      default: () => {
        return null
      }
    },
    catId: {
      type: String,
      default: null
    },
    categories: {
      type: Array,
      default: () => {
        return []
      }
    },
    creators: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      setCatId: null,
      gameSettings: {},
      needReload: false,
      checkAll: false,
      bulkCreatorIds: [],
      bulkData: {
        isAvailable: null,
        shareHolder: {
          receive: null,
          referral: 0,
          over: false,
          commission: null
        }
      },
      updateAllAccount: false
    }
  },
  computed: {
    title() {
      if(!this.account)
        return ''

      return `ตั้งค่าเกม [${this.account.user.username}]`
    },
    creatorItems() {
      return this.creators.filter((cre)=>{
        return cre.status === 'Active' && cre.catId === this.setCatId
      })
    },
    statusOptions() {
      return [
        { text: 'เปิด', value: true },
        { text: 'ปิด', value: false }
      ]
    },
    shareOptions() {
      let sharePercents = [{
        text: 'ไม่เปลี่ยนค่า',
        value: null
      }]
      for(let i=this.maxReceive; i>=0; i-=0.5) {
        sharePercents.push({
          text: i.toFixed(1),
          value: i
        })
      }
      return sharePercents
    },
    comOptions() {
      let comPercents = [{
        text: 'ไม่เปลี่ยนค่า',
        value: null
      }]

      for(let i=(this.maxCommission || 20); i>=0; i-=0.5) {
        comPercents.push({
          text: i.toFixed(1),
          value: i
        })
      }

      return comPercents
    },
    minReceive() {
      return _.min(this.creatorItems.map((item)=>{
        const creId = item._id
        return this.refSettings[creId].shareHolder.receive
      }))
    },
    maxReceive() {
      return _.max(this.creatorItems.map((item)=>{
        const creId = item._id
        return this.refSettings[creId].shareHolder.receive
      }))
    },
    minCommission() {
      return _.min(this.creatorItems.map((item)=>{
        const creId = item._id
        return this.refSettings[creId].shareHolder.commission
      }))
    },
    maxCommission() {
      return _.max(this.creatorItems.map((item)=>{
        const creId = item._id
        return this.refSettings[creId].shareHolder.commission
      }))
    },
    changeBulkStatus() {
      return this.bulkData.isAvailable
    },
    changeBulkShare() {
      return this.bulkData.shareHolder.receive
    },
    changeBulkCommisson() {
      return this.bulkData.shareHolder.commission
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    account() {
      this.gameSettings = JSON.parse(JSON.stringify(this.account.gameSettings))
    },
    checkAll(n, o) {
      this.bulkCreatorIds = []
      if(n){
        for(const item of this.creatorItems) {
          this.bulkCreatorIds.push(item._id)
        }
      }
    },
    setCatId() {
      this.gameSettings = JSON.parse(JSON.stringify(this.account.gameSettings))
      this.bulkCreatorIds = []
      this.checkAll = false
      this.bulkData = {
        isAvailable: null,
        shareHolder: {
          receive: null,
          referral: 0,
          over: false,
          commission: null
        }
      }
      this.updateAllAccount = false
    },
    changeBulkStatus(n, o) {
      if(n!==null) {
        this.gameSettings = this.bulkCreatorIds.reduce((setting, creId)=>{
          setting[creId].isAvailable = n
          return setting
        }, this.gameSettings)
      }
    },
    changeBulkShare(n, o) {
      if(n!==null) {
        this.gameSettings = this.bulkCreatorIds.reduce((setting, creId)=>{
          const agentReceive = this.refSettings[creId].shareHolder.receive
          const memberReceive = (n > agentReceive ? agentReceive : n)
          setting[creId].shareHolder.receive = memberReceive
          setting[creId].shareHolder.referral = agentReceive - memberReceive
          return setting
        }, this.gameSettings)
      }
    },
    changeBulkCommisson(n, o) {
      if(n!==null) {
        this.gameSettings = this.bulkCreatorIds.reduce((setting, creId)=>{
          const agentCommission = this.refSettings[creId].shareHolder.commission
          setting[creId].shareHolder.commission = (n > agentCommission ? agentCommission : n)
          return setting
        }, this.gameSettings)
      }
    }
  },
  methods: {
    setData() {
      this.setCatId = this.catId
      this.gameSettings = JSON.parse(JSON.stringify(this.account.gameSettings))
      this.bulkCreatorIds = []
      this.checkAll = false
      this.bulkData = {
        isAvailable: null,
        shareHolder: {
          receive: null,
          referral: 0,
          over: false,
          commission: null
        }
      }
      this.updateAllAccount = false
    },
    resetModal() {
      this.needReload = false
    },
    showModal() {
      this.$refs['account-game-modal'].show()
    },
    hideModal() {
      this.$refs['account-game-modal'].hide()
    },
    update(data) {
      GameService.updateGameSetting(data)
      .then((response)=>{
        if(response.success) {
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.needReload = true
        this.hideModal()
      })
    },
    resetChanged() {
      this.setData()
    },
    findDifferences(obj1, obj2) {
      const differences = {};

      function compareObjects(o1, o2, res) {
        for (let key in o1) {
          if (o1.hasOwnProperty(key)) {
            if (typeof o1[key] === 'object' && o1[key] !== null && typeof o2[key] === 'object' && o2[key] !== null) {
              res[key] = {};
              compareObjects(o1[key], o2[key], res[key]);
              // Remove empty objects
              if (Object.keys(res[key]).length === 0) {
                delete res[key];
              }
            } else if (o1[key] !== o2[key]) {
              res[key] = o2[key];
            }
          }
        }
        for (let key in o2) {
          if (o2.hasOwnProperty(key) && !o1.hasOwnProperty(key)) {
            res[key] = o2[key];
          }
        }
      }

      compareObjects(obj1, obj2, differences);
      return differences;
    },
    saveChanged() {
      const changeData = this.findDifferences(this.account.gameSettings, this.gameSettings)

      if (Object.keys(changeData).length) {
        const data = {
          accountIds: [this.account.accountId],
          refAccountId: this.refAccountId,
          update: changeData,
          updateAllAccount: this.updateAllAccount
        }
        this.update(data)
      } else
      if (this.updateAllAccount) {
        const data = {
          accountIds: [],
          refAccountId: this.refAccountId,
          update: this.gameSettings,
          updateAllAccount: this.updateAllAccount
        }
        this.update(data)
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#account-game-modal {
  .card {
    .card-header {
      border-color: #ffc107;

      .nav-tabs {
        .nav-item {
          .nav-link {
            &.active {
              background-color: #fdf1d1;
              font-weight: bold;
              color: #007bff;
              border-color: #ffc107;
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  .table {
    border-top: 0;

    thead {
      tr {

        th:first-child, td:first-child {
          border-left: 0;
        }

        th:last-child, td:last-child {
          border-right: 0;
        }

        th {
          border-top: 0px;
          vertical-align: middle;
          font-weight: normal;
          text-align: center;
        }

        td {
          vertical-align: middle;
          font-weight: normal;
          text-align: center;
        }
      }
    }

    tbody {
      background-color: #FFFFFF;
      tr {
        th:first-child, td:first-child {
          border-left: 0;
        }

        th:last-child, td:last-child {
          border-right: 0;
        }

        td {
          vertical-align: middle;
          text-align: center;
        }
      }
    }
  }
}
</style>
